import React from "react"
import {Helmet} from "react-helmet"
import {Link} from "gatsby";
import styles from "./index.module.css"
import logo from './logo.png'

export default class Index extends React.Component {

    render() {
        return (
            <>
                <Helmet htmlAttributes={{"lang": "de"}}
                >

                    <meta charSet="utf-8"/>
                    <title>Kreta Urlaub - Ferienwohnungen und Apartments</title>
                    <link rel="canonical" href="https://www.kreta-urlaub.at"/>
                </Helmet>
                <div className={styles.container}>
                    <div className={styles.logoContainer}>
                        <img className={styles.logo} src={logo} alt={"Kreta Urlaub - Ferienwohnungen und Apartments"}/>
                    </div>
                    <footer>
                        <Link to={"/impressum"}>Impressum &amp; Datenschutzvereinbarung</Link>
                    </footer>
                </div>
            </>
        );
    }
}
